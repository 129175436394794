<template>
  <div>
    <div>
      <span v-if="myBrand" class="mr_20">{{ myBrand.name }}</span>
      <span v-else-if="myComp" class="mr_20">{{ myComp.name }}</span>
      <span v-if="!myBrand && !myComp && myShop" class="mr_20">{{
        myShop.name
      }}</span>
    </div>
    <div class="search-bar">
      <el-form
        ref="searchRef"
        :model="searchForm"
        label-width="100px"
        size="small"
      >
        <div>
          <div>
            <el-row style="flex:1;">
              <el-col :span="5">
                <el-form-item label="商家" v-if="myBrand || myComp">
                  <el-select
                    style="width: 100%"
                    v-model="searchForm.businessId"
                    :remote-method="businessRemoteMethod"
                    :loading="businessLoading"
                    placeholder="选择商家"
                    clearable
                    filterable
                    remote
                    @focus="businessRemoteMethod()"
                    @change="serchData"
                  >
                    <el-option
                      v-for="item in businessList"
                      :key="item.id"
                      :label="item.full_name"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="账单编号">
                  <el-input
                    v-model="searchForm.billNo"
                    placeholder="请输入"
                    clearable
                    @keyup.enter.native="serchData"
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="应还款日期">
                  <el-date-picker
                    style="width: 100%"
                    v-model="repayment_date"
                    type="daterange"
                    value-format="yyyy-MM-dd"
                    range-separator="-"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    @change="onRefundDateChange"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="5">
                <el-form-item label="账单状态">
                  <el-select
                    style="width: 100%"
                    v-model="searchForm.billStageStatus"
                    clearable
                    placeholder="请选择"
                    @change="serchData"
                  >
                    <el-option
                      v-for="item in billStatusList"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="5  ">
                <el-form-item label="学员名称">
                  <el-input
                    v-model="searchForm.username"
                    placeholder="请输入学员名称"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item label="电话">
                  <el-input
                    v-model="searchForm.mobile"
                    placeholder="请输入电话"
                    clearable
                  ></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <el-form-item >
                </el-form-item>
              </el-col>
              <el-col :span="6">
                <div class="ml_20">
                  <el-button size="small" @click="onExport">导出</el-button>
                  <el-button type="primary" size="small" @click="serchData"
                    >搜索</el-button
                  >
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </el-form>
    </div>
    <div class="bill-table">
      <base-table
        :columns="stageBillList"
        :data="tableData"
        :pagination="searchForm"
        :total="total"
        @getData="getBillList"
        :loading="loading"
        :isPaginationShow="true"
        wrapperHeight="calc(100% - 45px)"
      >
        <el-table-column
          width="150px"
          slot="bill_no"
          label="账单编号"
          align="center"
        >
          <template slot-scope="scope">
            <el-button
              type="text"
              size="small"
              @click="checkDetail(scope.row)"
              >{{ scope.row.bill_no }}</el-button
            >
          </template>
        </el-table-column>
        <el-table-column slot="current_stage" label="期数" align="center">
          <template slot-scope="scope">
            {{ scope.row.current_stage }}/{{ scope.row.total_stage_count }}
          </template>
        </el-table-column>
        <el-table-column slot="bill_stage_status" label="状态" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.bill_stage_status == 1">未支付</span>
            <span v-if="scope.row.bill_stage_status == 2">未结清</span>
            <span v-if="scope.row.bill_stage_status == 3">已结清</span>
            <span v-if="scope.row.bill_stage_status == 4">已逾期</span>
          </template>
        </el-table-column>
        <el-table-column
          slot="repayment_business_interest"
          label="商家管理费"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.repayment_business_interest }}
          </template>
        </el-table-column>
        <el-table-column slot="payment_mode" label="还款模式" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.payment_mode == 0">当期支付</span>
            <span v-if="scope.row.payment_mode == 1">全部结清</span>
            <span v-if="scope.row.payment_mode == 2">部分结清</span>
            <span v-if="scope.row.payment_mode == 3">退课费结算</span>
          </template>
        </el-table-column>
        <el-table-column
          slot="remark"
          label="备注"
          align="center"
          width="150px"
        >
          <template slot-scope="scope">
            {{ scope.row.remark }}
          </template>
        </el-table-column>
      </base-table>
    </div>
  </div>
</template>

<script>
import { suggestBusinessList } from "@/api/business.js";
import { getMyBrand, getMyComp, getMyShop, getAuth } from "@/utils/index.js";
import columns from "./columns";
import { billStageRepaymentList } from "@/api/bill.js";
import config from "@/utils/config.js";


export default {
  name: "stageList",

  data() {
    return {
      managerType: localStorage.getItem("__managerType__"),
      getAuth: getAuth,
      myBrand: getMyBrand(),
      myComp: getMyComp(),
      myShop: getMyShop(),

      ...columns,
      loading: false,
      total: 0,
      tableData: [],

      searchForm: {
        page: 1,
        per_page: 10,
        businessId: "",
        billStageStatus: 0,
      },
      businessLoading: false,
      businessList: [],
      baseBusinessList: [],
      productLoading: false,
      productList: [],

      repayment_date: [],
      setDateRange: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      billStatusList: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "未支付",
        },
        {
          value: 2,
          label: "未结清",
        },
        {
          value: 3,
          label: "已结清",
        },
        {
          value: 4,
          label: "已逾期",
        },
      ],
    };
  },

  async mounted() {
        var _this = this;
        var date = new Date();
        var y = date.getFullYear();
        var m =
          date.getMonth() + 1 < 10
            ? "0" + (date.getMonth() + 1)
            : date.getMonth() + 1;
        var d = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();

        var start_day = y + "-" + m + "-" + "01";
        var end_day = `${y}-${m}-${d}`;

        _this.repayment_date = [start_day, end_day];
        this.searchForm.repaymentStartTime = start_day + " " + "00:00:00";
      this.searchForm.repaymentEndTime = end_day + " " + "23:59:59";
    this.initData();
    this.baseBusinessList = await this.getSelectBusiness();
  },

  methods: {
    serchData(){
      this.searchForm.page = 1
      this.searchForm.per_page = 10
      this.initData()
    },
    async initData() {
      this.getBillList();
    },
    async getBillList() {
      if (!this.myBrand && !this.myComp && this.myShop) {
        this.searchForm.businessId = this.myShop.id;
      }

      if (this.repayment_date && this.repayment_date.length == 2) {
        this.searchForm.repaymentStartTime =
          this.repayment_date[0] + " " + "00:00:00";
        this.searchForm.repaymentEndTime =
          this.repayment_date[1] + " " + "23:59:59";
      } else {
        this.searchForm.repaymentStartTime = "";
        this.searchForm.repaymentEndTime = "";
      }
      const { code, data } = await billStageRepaymentList(this.searchForm);
      this.loading = true;
      if (code == 200) {
        this.loading = false;
        this.tableData = data.list.map((item) => {
          if ((item.remark || "").length > 20) {
            item.remark = item.remark.substring(0, 18) + "...";
          }
          return item;
        });
        this.total = data.total;
      } else {
        this.loading = false;
      }
    },
    checkDetail(row) {
      const routeData = this.$router.resolve({
        path: "/bill/billInfo",
        query: {
          billNo: row.bill_no,
        },
      });
      window.open(routeData.href, "_blank");
    },
    onExport() {
      const url = `${
        config.BASE_URL
      }/export/billStageRepaymentList?token=${localStorage.getItem(
        "__token__"
      )}&businessId=${this.searchForm.businessId || ""}&billNo=${
        this.searchForm.billNo || ""
      }&billStageStatus=${this.searchForm.billStageStatus || ""}&repaymentStartTime=${
        this.searchForm.repaymentStartTime || ""
      }&repaymentEndTime=${this.searchForm.repaymentEndTime || ""}`;
      window.open(url, "_blank");
    },
    businessRemoteMethod(query) {
      if (query) {
        this.businessLoading = true;
        setTimeout(async () => {
          this.businessLoading = false;
          this.businessList = await this.getSelectBusiness(query);
        }, 200);
      } else {
        this.businessList = this.baseBusinessList;
      }
    },
    async getSelectBusiness(keyword) {
      const { code, data } = await suggestBusinessList({
        keyword: keyword || "",
      });
      if (code == 200) {
        return data;
      }
      return [];
    },
    onRefundDateChange(val) {
      console.log(this.repayment_date)
      if (val) {
        this.searchForm.repaymentStartTime = val[0] + " " + "00:00:00";
        this.searchForm.repaymentEndTime = val[1] + " " + "23:59:59";
      } else {
        this.searchForm.repaymentStartTime = "";
        this.searchForm.repaymentEndTime = "";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>